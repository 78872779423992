import React from 'react';
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import CircleIcon from '@mui/icons-material/Circle';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import ColorizeIcon from '@mui/icons-material/Colorize';
import { Box, Typography } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

const standardColors = [
    "transparent",
    "#C00000",
    "#FF0000",
    "#FFC000",
    "#FFFF00",
    "#92D050",
    "#00B050",
    "#00B0F0",
    "#0070C0",
    "#002060",
    "#7030A0"
];

const themeColors = [
    ['#FFFFFF', '#F2F2F2', '#D9D9D9', '#BFBFBF', '#A6A6A6', '#808080'],  // Theme colors 1
    ['#000000', '#808080', '#595959', '#404040', '#262626', '#0D0D0D'],  // Theme colors 2
    ['#E8E8E8', '#D0D0D0', '#ADADAD', '#747474', '#393939', '#161616'],  // Theme colors 3
    ['#0E2841', '#DAE9F8', '#A6C9EC', '#4C93D9', '#215C98', '#153D64'],  // Theme colors 4
    ['#156082', '#C0E6F5', '#83CCEB', '#44B3E1', '#104861', '#0B3040'],  // Theme colors 5
    ['#E97132', '#FBE2D5', '#F7C7AC', '#F1A983', '#BE5014', '#7E350E'],  // Theme colors 6
    ['#196B24', '#C1F0C8', '#83E28E', '#47D359', '#12501A', '#0D3512'],  // Theme colors 7
    ['#0F9ED5', '#CAEDFB', '#94DCF8', '#61CBF3', '#0C769E', '#074F69'],  // Theme colors 8
    ['#A02B93', '#F2CEEF', '#E49EDD', '#D86DCD', '#782170', '#51154A'],  // Theme colors 9
    ['#4FA72E', '#DAF2D0', '#B5E6A2', '#8ED973', '#3C7D22', '#275317']   // Theme colors 10
];

function MemoOptionsToolbar({
    colors,
    toolbarRef,
    isCellMemo,
    isMainMemo,
    cellColor,
    setCellColor,
    fontColor,
    setFontColor,
}) {

    const [cellColorMenuOpen, setCellColorMenuOpen] = React.useState(false);
    const [fontColorMenuOpen, setFontColorMenuOpen] = React.useState(false);

    const handleCommand = (command, value = null) => {
        if (command === "createlink") {
            const url = prompt("Enter the link here:", "http://");

            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.target = '_blank';
                anchor.textContent = selection.toString();
                range.deleteContents(); // Remove the selected text
                range.insertNode(anchor); // Insert the new anchor element
            }

            // document.execCommand(command, false, url);
        } else if (command === "unlink") {
            document.execCommand(command, false, null);
        } else {
            document.execCommand(command, false, value);
        }
    };

    return (
        <Box
            sx={{
                position: "fixed",
                top: isMainMemo ? "50px" : "unset",
                bottom: isMainMemo ? "unset" : "50px",
                left: "50%",
                transform: 'translateX(-50%)',
                height: "50px",
                zIndex: 9999,
            }}
        >
            <div
                tabIndex={0}
                ref={toolbarRef}
                style={{ 
                    width: "100%", 
                    display: "flex", 
                    alignItems: "center",
                    backgroundColor: colors.primary[600],
                    borderRadius: "10px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                }}
            >
                <ul
                    className="tool-list"
                    style={{ display: "flex", alignItems: "center", paddingLeft: "10px", paddingRight: "10px" }}
                >
                    {/* Formatting */}
                    <li>
                        <Tooltip title="Bold" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("bold")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <FormatBoldIcon sx={{fontSize: 'large'}}/>
                            </button>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Italic" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("italic")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <FormatItalicIcon sx={{fontSize: 'large'}}/>
                            </button>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Underline" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("underline")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <FormatUnderlinedIcon sx={{fontSize: 'large'}}/>
                            </button>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Strikethrough" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("strikethrough")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <StrikethroughSIcon sx={{fontSize: 'large'}}/>
                            </button>
                        </Tooltip>
                    </li>

                    {/* Links */}
                    <li>
                        <Tooltip title="Create Link" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("createlink")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <InsertLinkIcon sx={{fontSize: 'large'}}/>
                            </button>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Unlink" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("unlink")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <LinkOffIcon sx={{fontSize: 'large'}}/>
                            </button>
                        </Tooltip>
                    </li>

                    {/* Default Colors */}
                    <li>
                        <Tooltip title="Font Color (Default)" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => setFontColor(colors.primary[100])}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <CircleIcon 
                                    sx={{ 
                                        color: colors.primary[100],
                                        fontSize: 'large',
                                        border: '1px solid white',
                                        borderRadius: "50%",
                                    }} 
                                />
                            </button>
                        </Tooltip>
                    </li>

                    {/* Sky Blue Color */}
                    <li>
                        <Tooltip title="Font Color (Sky Blue)" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => setFontColor("#599DD5")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <CircleIcon 
                                    sx={{ 
                                        color: "#599DD5", 
                                        fontSize: 'large',
                                        border: '1px solid white',
                                        borderRadius: "50%",
                                    }} 
                                />
                            </button>
                        </Tooltip>
                    </li>

                    {/* Red Color */}
                    <li>
                        <Tooltip title="Font Color (Red)" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => setFontColor("red")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <CircleIcon 
                                    sx={{ 
                                        color: 'red', 
                                        fontSize: 'large',
                                        border: '1px solid white',
                                        borderRadius: "50%",
                                    }} 
                                />
                            </button>
                        </Tooltip>
                    </li>

                    {/* Font color */}
                    <li style={{ position: "relative" }}>
                        <Tooltip title="Font Color" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => {
                                    setCellColorMenuOpen(false);
                                    setFontColorMenuOpen(!fontColorMenuOpen);
                                }}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <FormatColorTextIcon sx={{ fontSize: 'large' }} />
                            </button>
                        </Tooltip>

                        {/* Other color menu */}
                        {fontColorMenuOpen && 
                            <div 
                                style={{
                                    position: "absolute",
                                    bottom: isMainMemo ? "calc(100% - 450px)" : "100%",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    alignItems: "center",
                                    zIndex: 9999,
                                    backgroundColor: colors.primary[600],
                                    borderRadius: "10px",
                                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        padding: "10px",
                                    }}
                                >
                                    <Typography
                                        variant="h7"
                                        sx={{
                                            width: "100%",
                                            fontWeight: "600",
                                        }}
                                    >
                                        Standard Colors
                                    </Typography>
                                    <ul
                                        className="tool-list"
                                        style={{ 
                                            display: "flex", 
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        {/* Standard colors */}
                                        {standardColors.map((color) => {

                                            if (color === "transparent") {
                                                return null;
                                            }

                                            return (
                                                <li key={color}>
                                                    <button
                                                        type="button"
                                                        onClick={() => setFontColor(color)}
                                                        style={{ color: color }}
                                                        className='tool--btn'
                                                    >
                                                        <SquareRoundedIcon
                                                            sx={{
                                                                color: color,
                                                                fontSize: 'large',
                                                                border: fontColor === color.toLowerCase() ? '2px solid ' + colors.primary[100] : '1px solid ' + colors.primary[900],
                                                            }}
                                                        />
                                                    </button>
                                                </li>
                                            );
                                        })}
                                    </ul>

                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "1px",
                                            backgroundColor: colors.primary[900],
                                        }}
                                    ></Box>

                                    <Typography
                                        variant="h7"
                                        sx={{
                                            width: "100%",
                                            fontWeight: "600",
                                            paddingTop: "10px",
                                        }}
                                    >
                                        Theme Colors
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        {themeColors.map((palette, index) => (
                                            <ul
                                                key={index}
                                                className="tool-list"
                                                style={{ 
                                                    position: "relative",
                                                    display: "flex", 
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {palette.map((shade) => (
                                                    <li key={shade}>
                                                        <button
                                                            type="button"
                                                            onClick={() => setFontColor(shade)}
                                                            style={{ color: shade }}
                                                            className='tool--btn'
                                                        >
                                                            <SquareRoundedIcon
                                                                sx={{
                                                                    color: shade,
                                                                    fontSize: 'large',
                                                                    border: fontColor === shade.toLowerCase() ? '2px solid ' + colors.primary[100] : '1px solid ' + colors.primary[900],
                                                                }}
                                                            />
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        ))}
                                    </Box>

                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "1px",
                                            backgroundColor: colors.primary[900],
                                        }}
                                    ></Box>

                                    {/* color picker */}
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%",
                                            gap: "10px",
                                            marginTop: "10px",
                                            borderRadius: "5px",
                                            '&:hover': {
                                                backgroundColor: colors.primary[700],
                                            },
                                        }}
                                    >
                                        <label
                                            style={{ 
                                                display: "flex", 
                                                flexDirection: "row", 
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "100%",
                                                padding: "5px",
                                                border: "1px solid " + colors.primary[900],
                                                borderRadius: "5px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <ColorizeIcon sx={{ fontSize: 'large', ml: 1 }} />
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    width: "100%",
                                                    fontWeight: "600",
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                Custom Color
                                            </Typography>
                                            <input
                                                type="color"
                                                onChange={(e) => setFontColor(e.target.value)}
                                                title="Font Color"
                                                style={{ 
                                                    width: 0,
                                                    height: 0,
                                                    opacity: 0,
                                                    position: "absolute",
                                                }}
                                            />
                                        </label>
                                    </Box>

                                    <div 
                                        className='triangle'
                                        style={{
                                            position: "absolute",
                                            bottom: !isMainMemo && "-6px",
                                            top: isMainMemo && "-6px",
                                            left: "50%",
                                            transform: "translateX(-50%)",
                                            width: 0,
                                            height: 0,
                                            borderLeft: "6px solid transparent",
                                            borderRight: "6px solid transparent",
                                            borderTop: !isMainMemo && "6px solid " + colors.primary[600],
                                            borderBottom: isMainMemo && "6px solid " + colors.primary[600],
                                        }}
                                    ></div>
                                </Box>
                            </div>
                        }
                    </li>

                    {/* Cell Color */}
                    {isCellMemo && (
                        <li style={{ position: "relative" }}>
                            <Tooltip title="Cell Color" placement='top' arrow>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setFontColorMenuOpen(false);
                                        setCellColorMenuOpen(!cellColorMenuOpen);
                                    }}
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                        padding: '10px',
                                        fontSize: '15px',
                                        color: colors.primary[100],
                                        transition: 'color 0.3s ease transform 0.2s ease',
                                    }}
                                >
                                    <ColorLensIcon sx={{ fontSize: 'large' }} />
                                </button>
                            </Tooltip>

                            {/* Cell color menu */}
                            {cellColorMenuOpen && 
                                <div 
                                    style={{
                                        position: "absolute",
                                        bottom: "100%",
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                        alignItems: "center",
                                        zIndex: 9999,
                                        backgroundColor: colors.primary[600],
                                        borderRadius: "10px",
                                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: "relative",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            padding: "10px",
                                        }}
                                    >
                                        <Typography
                                            variant="h7"
                                            sx={{
                                                width: "100%",
                                                fontWeight: "600",
                                            }}
                                        >
                                            Standard Colors
                                        </Typography>
                                        <ul
                                            className="tool-list"
                                            style={{ 
                                                display: "flex", 
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            {/* Standard colors */}
                                            {standardColors.map((color) => (
                                                <li key={color}>
                                                    <button
                                                        type="button"
                                                        onClick={() => setCellColor(color)}
                                                        style={{ color: color }}
                                                        className='tool--btn'
                                                    >
                                                        {color === "transparent" ?
                                                            <NotInterestedIcon
                                                                sx={{
                                                                    color: colors.primary[100],
                                                                    fontSize: 'large',
                                                                    border: cellColor === color ? '2px solid ' + colors.primary[100] : '1px solid ' + colors.primary[900],
                                                                }}
                                                            /> 
                                                            :
                                                            <SquareRoundedIcon
                                                                sx={{
                                                                    color: color,
                                                                    fontSize: 'large',
                                                                    border: cellColor === color ? '2px solid ' + colors.primary[100] : '1px solid ' + colors.primary[900],
                                                                }}
                                                            />
                                                        }
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>

                                        <Box
                                            sx={{
                                                width: "100%",
                                                height: "1px",
                                                backgroundColor: colors.primary[900],
                                            }}
                                        ></Box>

                                        <Typography
                                            variant="h7"
                                            sx={{
                                                width: "100%",
                                                fontWeight: "600",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            Theme Colors
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}
                                        >
                                            {themeColors.map((palette, index) => (
                                                <ul
                                                    key={index}
                                                    className="tool-list"
                                                    style={{ 
                                                        position: "relative",
                                                        display: "flex", 
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {palette.map((shade) => (
                                                        <li key={shade}>
                                                            <button
                                                                type="button"
                                                                onClick={() => setCellColor(shade)}
                                                                style={{ color: shade }}
                                                                className='tool--btn'
                                                            >
                                                                <SquareRoundedIcon
                                                                    sx={{
                                                                        color: shade,
                                                                        fontSize: 'large',
                                                                        border: cellColor === shade ? '2px solid ' + colors.primary[100] : '1px solid ' + colors.primary[900],
                                                                    }}
                                                                />
                                                            </button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ))}
                                        </Box>

                                        <Box
                                            sx={{
                                                width: "100%",
                                                height: "1px",
                                                backgroundColor: colors.primary[900],
                                            }}
                                        ></Box>

                                        {/* color picker */}
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "100%",
                                                gap: "10px",
                                                marginTop: "10px",
                                                borderRadius: "5px",
                                                '&:hover': {
                                                    backgroundColor: colors.primary[700],
                                                },
                                            }}
                                        >
                                            <label
                                                style={{ 
                                                    display: "flex", 
                                                    flexDirection: "row", 
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    width: "100%",
                                                    padding: "5px",
                                                    border: "1px solid " + colors.primary[900],
                                                    borderRadius: "5px",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <ColorizeIcon sx={{ fontSize: 'large', ml: 1 }} />
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        width: "100%",
                                                        fontWeight: "600",
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    Custom Color
                                                </Typography>
                                                <input
                                                    type="color"
                                                    onChange={(e) => setCellColor(e.target.value)}
                                                    title="Font Color"
                                                    style={{ 
                                                        width: 0,
                                                        height: 0,
                                                        opacity: 0,
                                                        position: "absolute",
                                                    }}
                                                />
                                            </label>
                                        </Box>

                                        <div 
                                            className='triangle'
                                            style={{
                                                position: "absolute",
                                                bottom: "-6px",
                                                left: "50%",
                                                transform: "translateX(-50%)",
                                                width: 0,
                                                height: 0,
                                                borderLeft: "6px solid transparent",
                                                borderRight: "6px solid transparent",
                                                borderTop: "6px solid " + colors.primary[600],
                                                
                                            }}
                                        ></div>
                                    </Box>
                                </div>
                            }
                        </li>
                    )}
                </ul>
            </div>
        </Box>
    )
}

export default MemoOptionsToolbar;