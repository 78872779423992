import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import Axios from 'axios';
import getStudentName from '../../../utils/getStudentName';


export default function AdminActionAccordion({ 
    courseId, 
    lectureModules, 
    students,
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen, 
    setSuccessAlertMessage,
    setErrorAlertMessage,
    setDataUpdated,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [module, setModule] = React.useState('');
    const [expirationDate, setExpirationDate] = React.useState(dayjs().add(14, 'day').hour(23).minute(59).second(59));
    const [selectedStudents, setSelectedStudents] = React.useState([]);
    const [link, setLink] = React.useState(''); // link to zoom recording
    const [description, setDescription] = React.useState(''); // description of zoom recording

    React.useEffect(() => {
        const lecture_module = lectureModules.find((lectureModule) => lectureModule.id === module);
        if (!lecture_module) return;

        const students_who_need_zoom_recording = [];
        lecture_module.attendances.forEach((attendance) => {
            if (attendance.need_zoom_recording) {
                students_who_need_zoom_recording.push(attendance.student_id);
            }
        });

        setSelectedStudents(
            students.filter((student) => students_who_need_zoom_recording.includes(student.id))
        )

    }, [module]);

    const handleModuleChange = (event) => {
        event.preventDefault();
        setModule(event.target.value);
    };

    React.useEffect(() => {
        console.log(selectedStudents);
    }, [selectedStudents]);

    const handleCreate = async (event) => {
        event.preventDefault();       

        // check if valid inputs are given 
        if (module === '') {
            setErrorAlertMessage("Please select a module!");
            setErrorAlertOpen(true);
            return;
        }

        if (selectedStudents.length === 0) {
            setErrorAlertMessage("Please select at least one student!");
            setErrorAlertOpen(true);
            return;
        }

        if (link === '') {
            setErrorAlertMessage("Please enter a link!");
            setErrorAlertOpen(true);
            return;
        }

        if (expirationDate === null) {
            setErrorAlertMessage("Please enter an expiration date!");
            setErrorAlertOpen(true);
            return;
        }
        
        if (expirationDate < dayjs()) {
            setErrorAlertMessage("Please enter a valid expiration date!");
            setErrorAlertOpen(true);
            return;
        }

        setBackdropOpen(true);
        
        // post zoom recordings
        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/zoom_recordings`, {
            link: link,
            expiration_date: expirationDate,
            event_id: module,
            student_list: selectedStudents,
            description: description,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((res) => {
            setSuccessAlertMessage(res.data.message);
            setSuccessAlertOpen(true);
        }).catch((err) => {
            setErrorAlertMessage(err.response.data.message || err.response.data.error);
            setErrorAlertOpen(true);
        }).finally(() => {
            setBackdropOpen(false);
            setDataUpdated(true);
        });
    };

    // Function to format the event start date (ex. (Mon) 07/20/2024)
    const formatDate = (date) => {
        const options = { weekday: 'short', month: '2-digit', day: '2-digit', year: 'numeric' };
        const formattedDate = new Date(date).toLocaleDateString('en-US', options);
        const [dayOfWeek, rest] = formattedDate.split(', ');
        const [month, day, year] = rest.split('/');
        return `(${dayOfWeek}) ${month}/${day}/${year}`;
    };

    return (
        <div style={{
            width: "100%",
            marginBottom: "1rem",
        }}>
            <Accordion
                style={{
                    backgroundColor: colors.primary[750],
                }}
                component="div"
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h6'>Create a Zoom Recording Link</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                            display: "flex",
                            flexDirection: "column",
                            mr: "1rem",
                        }}
                    >
                        <FormControl fullWidth sx={{ minWidth: "49%", backgroundColor: colors.primary[600] }}>
                            <InputLabel id="demo-simple-select-label">Select a module</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={module}
                                label="Select a module"
                                onChange={handleModuleChange}
                            >
                                {lectureModules.map((lectureModule) => {
                                    return (
                                        <MenuItem key={lectureModule.id} value={lectureModule.id}>
                                            {/* {lectureModule.title}, {new Date(lectureModule.start).toDateString()} */}
                                            {lectureModule.title}, {formatDate(lectureModule.start)}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                            minWidth: "100%",
                        }}>
                            <TextField
                                id="filled-basic"
                                label="Link"
                                placeholder='A link should begin with "http://"'
                                variant="filled"
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                                sx={{
                                    flex: "1",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[600],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                            />
                            <TextField
                                id="filled-basic"
                                label="Description"
                                placeholder='A description of the zoom recording'
                                variant="filled"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                sx={{
                                    flex: "1",
                                    '& .MuiFilledInput-root': {
                                        backgroundColor: colors.primary[600],
                                        ":focus": {
                                            backgroundColor: 'white',
                                        },
                                    },
                                }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "1rem",
                                }}
                            >
                                <Autocomplete
                                    multiple
                                    id="tags-outlined-1"
                                    options={students !== null ? students : []}
                                    getOptionLabel={(students) => getStudentName(students)}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select Students"
                                        />
                                    )}
                                    sx={{ width: "100%" }}
                                    value={selectedStudents}
                                    onChange={(event, value) => setSelectedStudents(value)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        label="Expiration Date"
                                        value={expirationDate}
                                        onChange={(newValue) => setExpirationDate(newValue)}
                                        sx={{
                                            width: "16rem",
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>                  
                        {/* <div 
                            style={{
                                width: "100%",
                                marginBottom: "1rem",
                            }}
                        >
                            <Accordion
                                style={{
                                    backgroundColor: colors.primary[750],
                                }}
                                component="div"
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant='h6'>Memo (Optional)</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box 
                                        display="flex"
                                    >
                                        <TextField
                                            id="filled-basic"
                                            variant="filled"
                                            fullWidth={true}
                                            multiline={true}
                                            rows={5}
                                            sx={{
                                                minWidth: "40%",
                                                borderRadius: "5px",
                                                '& .MuiFilledInput-root': {
                                                    ":focus": {
                                                        backgroundColor: 'white',
                                                    },
                                                },
                                            }}
                                            onChange={(e) => setMemo(e.target.value)}
                                            />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </div> */}
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: colors.primary[300],
                                color: "white",
                                float: "right",
                                marginTop: "0.5rem",
                                marginBottom: "1rem",
                                width: "10rem",
                            }}
                            onClick={(e) => handleCreate(e)}
                        >
                            Create
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}