

export default function sanitizeHTML(input) {
    // Regex to match tags and check if they are allowed
    const allowedTags = /^(<\/?(b|strong|i|em|u|s|strike|a|br)\b[^>]*>)$/i;
    const allTags = /<\/?[^>]+>/gi; // Match all HTML tags

    // Process input to remove disallowed tags
    return input.replace(allTags, (tag) => {
        return allowedTags.test(tag) ? tag : ''; // Retain allowed tags, remove others
    });
}