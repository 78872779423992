import React from 'react';
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import CircleIcon from '@mui/icons-material/Circle';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ColorizeIcon from '@mui/icons-material/Colorize';
import { Box, Typography } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

const standardColors = [
    "transparent",
    "#C00000",
    "#FF0000",
    "#FFC000",
    "#FFFF00",
    "#92D050",
    "#00B050",
    "#00B0F0",
    "#0070C0",
    "#002060",
    "#7030A0"
];

const themeColors = [
    ['#FFFFFF', '#F2F2F2', '#D9D9D9', '#BFBFBF', '#A6A6A6', '#808080'],  // Theme colors 1
    ['#000000', '#808080', '#595959', '#404040', '#262626', '#0D0D0D'],  // Theme colors 2
    ['#E8E8E8', '#D0D0D0', '#ADADAD', '#747474', '#393939', '#161616'],  // Theme colors 3
    ['#0E2841', '#DAE9F8', '#A6C9EC', '#4C93D9', '#215C98', '#153D64'],  // Theme colors 4
    ['#156082', '#C0E6F5', '#83CCEB', '#44B3E1', '#104861', '#0B3040'],  // Theme colors 5
    ['#E97132', '#FBE2D5', '#F7C7AC', '#F1A983', '#BE5014', '#7E350E'],  // Theme colors 6
    ['#196B24', '#C1F0C8', '#83E28E', '#47D359', '#12501A', '#0D3512'],  // Theme colors 7
    ['#0F9ED5', '#CAEDFB', '#94DCF8', '#61CBF3', '#0C769E', '#074F69'],  // Theme colors 8
    ['#A02B93', '#F2CEEF', '#E49EDD', '#D86DCD', '#782170', '#51154A'],  // Theme colors 9
    ['#4FA72E', '#DAF2D0', '#B5E6A2', '#8ED973', '#3C7D22', '#275317']   // Theme colors 10
];

function MainMemoOptionsToolbar({
    colors,
    toolbarRef,
    isMainMemo,
}) {

    const [backgroundColorMenuOpen, setBackgroundColorMenuOpen] = React.useState(false);
    const [fontColorMenuOpen, setFontColorMenuOpen] = React.useState(false);
    const [selectionFontColor, setSelectionFontColor] = React.useState("");
    const [selectionBackgroundColor, setSelectionBackgroundColor] = React.useState("");

    const isManuallyChangingFontSize = React.useRef(false);

    React.useEffect(() => {
        function handleSelectTexts() {
            if (isManuallyChangingFontSize.current) {
                return;
            }

            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                let parent = range.commonAncestorContainer;

                let fontSize = 16;
                let initialParent = parent;
                let parentForFontSize = initialParent;
                let parentForFontColor = initialParent;
                let parentForBackgroundColor = initialParent;

                // for font size
                while (true) {

                    if (parentForFontSize === undefined || parentForFontSize === null) {
                        break;
                    }

                    if (parentForFontSize && parentForFontSize.tagName !== 'SPAN' && parentForFontSize.tagName !== 'FONT') {
                        parentForFontSize = parentForFontSize.parentNode;
                        continue;
                    }

                    // here it is SPAN or FONT

                    if (parentForFontSize && (parentForFontSize.tagName === 'SPAN' || parentForFontSize.tagName === 'FONT')) {
                        if (parentForFontSize.style.fontSize) {
                            fontSize = parentForFontSize.style.fontSize.replace('px', '');
                        } else if (parentForFontSize.size) {
                            fontSize = parentForFontSize.size;
                        }
                        break;
                    } else {
                        parentForFontSize = parentForFontSize.parentNode;
                    }
                }

                document.getElementById('font-size-select').value = fontSize;

                while (true) {

                    if (parentForFontColor === undefined || parentForFontColor === null) {
                        break;
                    }

                    if (parentForFontColor && parentForFontColor.tagName !== 'SPAN' && parentForFontColor.tagName !== 'FONT') {
                        parentForFontColor = parentForFontColor.parentNode;
                        continue;
                    }

                    // here it is SPAN or FONT
                    if (parentForFontColor && (parentForFontColor.tagName === 'SPAN' || parentForFontColor.tagName === 'FONT')) {
                        if (parentForFontColor.style.color) {
                            setSelectionFontColor(parentForFontColor.style.color);
                        } else if (parentForFontColor.color) {
                            setSelectionFontColor(parentForFontColor.color);
                        }
                        break;
                    } else {
                        parentForFontColor = parentForFontColor.parentNode;
                    }
                }

                while (true) {
                        
                    if (parentForBackgroundColor === undefined || parentForBackgroundColor === null) {
                        break;
                    }

                    if (parentForBackgroundColor && parentForBackgroundColor.tagName !== 'SPAN' && parentForBackgroundColor.tagName !== 'FONT') {
                        parentForBackgroundColor = parentForBackgroundColor.parentNode;
                        continue;
                    }

                    // here it is SPAN or FONT
                    if (parentForBackgroundColor && (parentForBackgroundColor.tagName === 'SPAN' || parentForBackgroundColor.tagName === 'FONT')) {
                        if (parentForBackgroundColor.style.backgroundColor) {
                            setSelectionBackgroundColor(rgbToHex(parentForBackgroundColor.style.backgroundColor));
                        }
                        break;
                    } else {
                        parentForBackgroundColor = parentForBackgroundColor.parentNode;
                    }
                }
            }
        }

        // when selecting texts, font size in select should be adjusted
        document.addEventListener('selectionchange', handleSelectTexts);

        return () => {
            document.removeEventListener('selectionchange', handleSelectTexts);
        }

    }, []);

    const rgbToHex = (rgb) => {

        // rgb: 'rgb(255, 255, 255)' for example
        // need to split the string and get the numbers
        try {
            const r = rgb.split(',')[0].split('(')[1].trim();
            const g = rgb.split(',')[1].trim();
            const b = rgb.split(',')[2].split(')')[0].trim();

            const hex = "#" + ((1 << 24) + (parseInt(r) << 16) + (parseInt(g) << 8) + parseInt(b)).toString(16).slice(1);

            return hex;
        } catch (error) {
            return "";
        }
    };

    const handleCommand = (command, value = null) => {
        if (command === "createlink") {
            const url = prompt("Enter the link here:", "http://");

            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.target = '_blank';
                anchor.textContent = selection.toString();
                range.deleteContents(); // Remove the selected text
                range.insertNode(anchor); // Insert the new anchor element
            }

            // document.execCommand(command, false, url);
        } else if (command === "unlink") {
            document.execCommand(command, false, null);
        } else if (command === "foreColor") {
            document.execCommand(command, false, value);
        } else if (command === "fontSize") {

            const selection = window.getSelection();
            if (selection.rangeCount > 0) {

                const range = selection.getRangeAt(0);

                // Check if the selection is collapsed (no text selected, just a cursor position)
                if (range.collapsed) {

                    // Create a new <span> with the desired font size
                    const span = document.createElement('span');
                    span.style.fontSize = value + "px";
                    span.appendChild(document.createTextNode('\u200B')); // Zero-width space to keep the span in place

                    // Insert the span at the cursor position
                    range.insertNode(span);

                    // Move the cursor inside the span
                    const newRange = document.createRange();
                    newRange.setStart(span, 1); // Position the cursor after the zero-width space
                    newRange.collapse(true); // Collapse the range to just a cursor point
                    selection.removeAllRanges();
                    selection.addRange(newRange);

                } else {

                    // Extract all the nodes within the range
                    const nodes = [];
                    const walker = document.createTreeWalker(
                        range.commonAncestorContainer,
                        NodeFilter.SHOW_TEXT,
                        {
                            acceptNode: (node) => {
                                // Only consider text nodes fully or partially within the selection
                                const nodeRange = document.createRange();
                                nodeRange.selectNodeContents(node);
                                return range.intersectsNode(node) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
                            }
                        }
                    );

                    while (walker.nextNode()) {
                        nodes.push(walker.currentNode);
                    }

                    if (nodes.length > 0) {
                        // Wrap each text node individually with a new <span>
                        nodes.forEach((textNode) => {
                            const nodeRange = document.createRange();
                            nodeRange.selectNodeContents(textNode);

                            // Adjust the start and end of the range if it's partially selected
                            if (nodeRange.compareBoundaryPoints(Range.START_TO_START, range) < 0) {
                                nodeRange.setStart(range.startContainer, range.startOffset);
                            }
                            if (nodeRange.compareBoundaryPoints(Range.END_TO_END, range) > 0) {
                                nodeRange.setEnd(range.endContainer, range.endOffset);
                            }

                            const span = document.createElement('span');
                            span.style.fontSize = value + "px";
                            nodeRange.surroundContents(span);
                        });
                    } else {
                        // see if the selection is inside a span with a font size
                        let parent = range.commonAncestorContainer;
                        while (parent && parent.tagName !== 'SPAN') {
                            parent = parent.parentNode;
                        }

                        // see if parent is a span with a font size and its text is the same as the selection
                        if (parent && parent.tagName === 'SPAN' && parent.textContent === selection.toString()) {

                            // remove children spans
                            const spans = parent.querySelectorAll('span');
                            spans.forEach(span => {
                                const content = span.textContent;
                                span.replaceWith(content);
                            });

                            parent.style.fontSize = value + "px";
                        } else {
                            // add content to a new span
                            const span = document.createElement('span');
                            span.style.fontSize = value + "px";
                            range.surroundContents(span);

                            // remove children spans
                            const spans = span.querySelectorAll('span');
                            spans.forEach(span => {
                                const content = span.textContent;
                                span.replaceWith(content);
                            });
                        }
                    }
                }

                setSelectionFontColor(value);
            }
        } else {
            document.execCommand(command, false, value);
        }
    };

    return (
        <Box
            sx={{
                position: "fixed",
                top: isMainMemo ? "50px" : "unset",
                bottom: isMainMemo ? "unset" : "50px",
                left: "50%",
                transform: 'translateX(-50%)',
                height: "50px",
                zIndex: 9999,
            }}
        >
            <div
                tabIndex={0}
                ref={toolbarRef}
                style={{ 
                    width: "100%", 
                    display: "flex", 
                    alignItems: "center",
                    backgroundColor: colors.primary[600],
                    borderRadius: "10px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                }}
            >
                <ul
                    className="tool-list"
                    style={{ display: "flex", alignItems: "center", paddingLeft: "10px", paddingRight: "10px" }}
                >
                    {/* Alignment */}
                    <li>
                        <Tooltip title="Left Align" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("justifyLeft")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <FormatAlignLeftIcon sx={{fontSize: 'large'}}/>
                            </button>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Center Align" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("justifyCenter")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <FormatAlignCenterIcon sx={{fontSize: 'large'}} />
                            </button>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Right Align" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("justifyRight")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <FormatAlignRightIcon sx={{fontSize: 'large'}}/>
                            </button>
                        </Tooltip>
                    </li>

                    {/* Formatting */}
                    <li>
                        <Tooltip title="Bold" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("bold")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <FormatBoldIcon sx={{fontSize: 'large'}}/>
                            </button>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Italic" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("italic")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <FormatItalicIcon sx={{fontSize: 'large'}}/>
                            </button>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Underline" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("underline")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <FormatUnderlinedIcon sx={{fontSize: 'large'}}/>
                            </button>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Strikethrough" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("strikethrough")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <StrikethroughSIcon sx={{fontSize: 'large'}}/>
                            </button>
                        </Tooltip>
                    </li>

                    {/* Lists */}
                    <li>
                        <Tooltip title="Numbered List" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("insertOrderedList")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <FormatListNumberedIcon sx={{fontSize: 'large'}}/>
                            </button>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Bulleted List" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("insertUnorderedList")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <FormatListBulletedIcon sx={{fontSize: 'large'}}/>
                            </button>
                        </Tooltip>
                    </li>

                    {/* Links */}
                    <li>
                        <Tooltip title="Create Link" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("createlink")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <InsertLinkIcon sx={{fontSize: 'large'}}/>
                            </button>
                        </Tooltip>
                    </li>
                    <li>
                        <Tooltip title="Unlink" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("unlink")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <LinkOffIcon sx={{fontSize: 'large'}}/>
                            </button>
                        </Tooltip>
                    </li>

                    {/* Default Colors */}
                    <li>
                        <Tooltip title="Font Color (Default)" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("foreColor", colors.primary[100])}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <CircleIcon 
                                    sx={{ 
                                        color: colors.primary[100],
                                        fontSize: 'large',
                                        border: '1px solid white',
                                        borderRadius: "50%",
                                    }} 
                                />
                            </button>
                        </Tooltip>
                    </li>

                    {/* Sky Blue Color */}
                    <li>
                        <Tooltip title="Font Color (Sky Blue)" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("foreColor", "#599DD5")}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <CircleIcon 
                                    sx={{ 
                                        color: "#599DD5", 
                                        fontSize: 'large',
                                        border: '1px solid white',
                                        borderRadius: "50%",
                                    }} 
                                />
                            </button>
                        </Tooltip>
                    </li>

                    {/* Red Color */}
                    <li>
                        <Tooltip title="Font Color (Red)" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => handleCommand("foreColor", 'red')}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <CircleIcon 
                                    sx={{ 
                                        color: 'red', 
                                        fontSize: 'large',
                                        border: '1px solid white',
                                        borderRadius: "50%",
                                    }} 
                                />
                            </button>
                        </Tooltip>
                    </li>

                    {/* Font color */}
                    <li style={{ position: "relative" }}>
                        <Tooltip title="Font Color" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => {
                                    setBackgroundColorMenuOpen(false);
                                    setFontColorMenuOpen(!fontColorMenuOpen);
                                }}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <FormatColorTextIcon sx={{ fontSize: 'large' }} />
                            </button>
                        </Tooltip>

                        {/* Other color menu */}
                        {fontColorMenuOpen && 
                            <div 
                                style={{
                                    position: "absolute",
                                    bottom: isMainMemo ? "calc(100% - 450px)" : "100%",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    alignItems: "center",
                                    zIndex: 9999,
                                    backgroundColor: colors.primary[600],
                                    borderRadius: "10px",
                                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        padding: "10px",
                                    }}
                                >
                                    <Typography
                                        variant="h7"
                                        sx={{
                                            width: "100%",
                                            fontWeight: "600",
                                        }}
                                    >
                                        Standard Colors
                                    </Typography>
                                    <ul
                                        className="tool-list"
                                        style={{ 
                                            display: "flex", 
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        {/* Standard colors */}
                                        {standardColors.map((color) => {

                                            if (color === "transparent") {
                                                return null;
                                            }

                                            return (
                                                <li key={color}>
                                                    <button
                                                        type="button"
                                                        onClick={() => handleCommand("foreColor", color)}
                                                        style={{ color: color }}
                                                        className='tool--btn'
                                                    >
                                                        <SquareRoundedIcon
                                                            sx={{
                                                                color: color,
                                                                fontSize: 'large',
                                                                border: selectionFontColor === color.toLowerCase() ? '2px solid ' + colors.primary[100] : '1px solid ' + colors.primary[900],
                                                            }}
                                                        />
                                                    </button>
                                                </li>
                                            );
                                        })}
                                    </ul>

                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "1px",
                                            backgroundColor: colors.primary[900],
                                        }}
                                    ></Box>

                                    <Typography
                                        variant="h7"
                                        sx={{
                                            width: "100%",
                                            fontWeight: "600",
                                            paddingTop: "10px",
                                        }}
                                    >
                                        Theme Colors
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        {themeColors.map((palette, index) => (
                                            <ul
                                                key={index}
                                                className="tool-list"
                                                style={{ 
                                                    position: "relative",
                                                    display: "flex", 
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {palette.map((shade) => (
                                                    <li key={shade}>
                                                        <button
                                                            type="button"
                                                            onClick={() => handleCommand("foreColor", shade)}
                                                            style={{ color: shade }}
                                                            className='tool--btn'
                                                        >
                                                            <SquareRoundedIcon
                                                                sx={{
                                                                    color: shade,
                                                                    fontSize: 'large',
                                                                    border: selectionFontColor === shade.toLowerCase() ? '2px solid ' + colors.primary[100] : '1px solid ' + colors.primary[900],
                                                                }}
                                                            />
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        ))}
                                    </Box>

                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "1px",
                                            backgroundColor: colors.primary[900],
                                        }}
                                    ></Box>

                                    {/* color picker */}
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%",
                                            gap: "10px",
                                            marginTop: "10px",
                                            borderRadius: "5px",
                                            '&:hover': {
                                                backgroundColor: colors.primary[700],
                                            },
                                        }}
                                    >
                                        <label
                                            style={{ 
                                                display: "flex", 
                                                flexDirection: "row", 
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "100%",
                                                padding: "5px",
                                                border: "1px solid " + colors.primary[900],
                                                borderRadius: "5px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <ColorizeIcon sx={{ fontSize: 'large', ml: 1 }} />
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    width: "100%",
                                                    fontWeight: "600",
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                Custom Color
                                            </Typography>
                                            <input
                                                type="color"
                                                onChange={(e) => handleCommand("foreColor", e.target.value)}
                                                title="Font Color"
                                                style={{ 
                                                    width: 0,
                                                    height: 0,
                                                    opacity: 0,
                                                    position: "absolute",
                                                }}
                                            />
                                        </label>
                                    </Box>

                                    <div 
                                        className='triangle'
                                        style={{
                                            position: "absolute",
                                            bottom: !isMainMemo && "-6px",
                                            top: isMainMemo && "-6px",
                                            left: "50%",
                                            transform: "translateX(-50%)",
                                            width: 0,
                                            height: 0,
                                            borderLeft: "6px solid transparent",
                                            borderRight: "6px solid transparent",
                                            borderTop: !isMainMemo && "6px solid " + colors.primary[600],
                                            borderBottom: isMainMemo && "6px solid " + colors.primary[600],
                                        }}
                                    ></div>
                                </Box>
                            </div>
                        }
                    </li>

                    {/* Font Background Color */}
                    <li style={{ position: "relative" }}>
                        <Tooltip title="Background Color" placement='top' arrow>
                            <button
                                type="button"
                                onClick={() => {
                                    setFontColorMenuOpen(false);
                                    setBackgroundColorMenuOpen(!backgroundColorMenuOpen);
                                }}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '10px',
                                    fontSize: '15px',
                                    color: colors.primary[100],
                                    transition: 'color 0.3s ease transform 0.2s ease',
                                }}
                            >
                                <FormatColorFillIcon sx={{ fontSize: 'large' }} />
                            </button>
                        </Tooltip>

                        {/* Font Background color menu */}
                        {backgroundColorMenuOpen && 
                            <div 
                                style={{
                                    position: "absolute",
                                    bottom: isMainMemo ? "calc(100% - 450px)" : "100%",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    alignItems: "center",
                                    zIndex: 9999,
                                    backgroundColor: colors.primary[600],
                                    borderRadius: "10px",
                                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        padding: "10px",
                                    }}
                                >
                                    <Typography
                                        variant="h7"
                                        sx={{
                                            width: "100%",
                                            fontWeight: "600",
                                        }}
                                    >
                                        Standard Colors
                                    </Typography>
                                    <ul
                                        className="tool-list"
                                        style={{ 
                                            display: "flex", 
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        {/* Standard colors */}
                                        {standardColors.map((color) => (
                                            <li key={color}>
                                                <button
                                                    type="button"
                                                    onClick={() => handleCommand("backColor", color)}
                                                    style={{ color: color }}
                                                    className='tool--btn'
                                                >
                                                    {color === "transparent" ?
                                                        <NotInterestedIcon
                                                            sx={{
                                                                color: colors.primary[100],
                                                                fontSize: 'large',
                                                                border: selectionBackgroundColor === color.toLowerCase() ? '2px solid ' + colors.primary[100] : '1px solid ' + colors.primary[900],
                                                            }}
                                                        /> 
                                                        :
                                                        <SquareRoundedIcon
                                                            sx={{
                                                                color: color,
                                                                fontSize: 'large',
                                                                border: selectionBackgroundColor === color.toLowerCase() ? '2px solid ' + colors.primary[100] : '1px solid ' + colors.primary[900],
                                                            }}
                                                        />
                                                    }
                                                </button>
                                            </li>
                                        ))}
                                    </ul>

                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "1px",
                                            backgroundColor: colors.primary[900],
                                        }}
                                    ></Box>

                                    <Typography
                                        variant="h7"
                                        sx={{
                                            width: "100%",
                                            fontWeight: "600",
                                            paddingTop: "10px",
                                        }}
                                    >
                                        Theme Colors
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        {themeColors.map((palette, index) => (
                                            <ul
                                                key={index}
                                                className="tool-list"
                                                style={{ 
                                                    position: "relative",
                                                    display: "flex", 
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {palette.map((shade) => (
                                                    <li key={shade}>
                                                        <button
                                                            type="button"
                                                            onClick={() => handleCommand("backColor", shade)}
                                                            style={{ color: shade }}
                                                            className='tool--btn'
                                                        >
                                                            <SquareRoundedIcon
                                                                sx={{
                                                                    color: shade,
                                                                    fontSize: 'large',
                                                                    border: selectionBackgroundColor === shade.toLowerCase() ? '2px solid ' + colors.primary[100] : '1px solid ' + colors.primary[900],
                                                                }}
                                                            />
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        ))}
                                    </Box>

                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "1px",
                                            backgroundColor: colors.primary[900],
                                        }}
                                    ></Box>

                                    {/* color picker */}
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%",
                                            gap: "10px",
                                            marginTop: "10px",
                                            borderRadius: "5px",
                                            '&:hover': {
                                                backgroundColor: colors.primary[700],
                                            },
                                        }}
                                    >
                                        <label
                                            style={{ 
                                                display: "flex", 
                                                flexDirection: "row", 
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "100%",
                                                padding: "5px",
                                                border: "1px solid " + colors.primary[900],
                                                borderRadius: "5px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <ColorizeIcon sx={{ fontSize: 'large', ml: 1 }} />
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    width: "100%",
                                                    fontWeight: "600",
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                Custom Color
                                            </Typography>
                                            <input
                                                type="color"
                                                onChange={(e) => handleCommand("backColor", e.target.value)}
                                                title="Font Color"
                                                style={{ 
                                                    width: 0,
                                                    height: 0,
                                                    opacity: 0,
                                                    position: "absolute",
                                                }}
                                            />
                                        </label>
                                    </Box>

                                    <div 
                                        className='triangle'
                                        style={{
                                            position: "absolute",
                                            bottom: !isMainMemo && "-6px",
                                            top: isMainMemo && "-6px",
                                            left: "50%",
                                            transform: "translateX(-50%)",
                                            width: 0,
                                            height: 0,
                                            borderLeft: "6px solid transparent",
                                            borderRight: "6px solid transparent",
                                            borderTop: !isMainMemo && "6px solid " + colors.primary[600],
                                            borderBottom: isMainMemo && "6px solid " + colors.primary[600],                                            
                                        }}
                                    ></div>
                                </Box>
                            </div>
                        }
                    </li>

                    {/* Font size */}
                    <li>
                        <select
                            id='font-size-select'
                            defaultValue={16}
                            onChange={(e) => {
                                isManuallyChangingFontSize.current = true;

                                handleCommand("fontSize", e.target.value);

                                setTimeout(() => {
                                    isManuallyChangingFontSize.current = false;
                                }, 0);
                            }}
                            style={{
                                background: 'none',
                                cursor: 'pointer',
                                padding: '10px',
                                fontSize: '12px',
                                color: colors.primary[100],
                                transition: 'color 0.3s ease transform 0.2s ease',
                                padding: "5px",
                                borderRadius: "5px",
                            }}
                        >
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="14">14</option>
                            <option value="16">16</option>
                            <option value="18">18</option>
                            <option value="20">20</option>
                            <option value="24">24</option>
                            <option value="26">26</option>
                            <option value="28">28</option>
                            <option value="36">36</option>
                            <option value="48">48</option>
                            <option value="72">72</option>
                        </select>
                    </li>
                </ul>
            </div>
        </Box>
    )
}

export default MainMemoOptionsToolbar;