import React from "react";
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import "./TextEditor.css"; // Import the CSS file
import RateReviewIcon from '@mui/icons-material/RateReview';
import MainMemoOptionsToolbar from "./MainMemoOptionsToolbar";

const TextEditor = ({
    memo,
    memoId,
    socket,
    userId,
    activeUsersNum,
    colors,
}) => {
    const outputRef = React.useRef(null);
    const [isEditing, setIsEditing] = React.useState(false);
    const containerRef = React.useRef(null);
    const toolbarRef = React.useRef(null);

    const [memoOptionsToolbarOpen, setMemoOptionsToolbarOpen] = React.useState(false);
    const [prevMemo, setPrevMemo] = React.useState(memo ? memo : "");
    const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);

    React.useEffect(() => {
        if (isEditing && outputRef.current) {
            outputRef.current.innerHTML = memo;
        }
    }, [isEditing, memo]);

    React.useEffect(() => {
        const handleClickoutside = (e) => {
            if (containerRef.current && !containerRef.current.contains(e.target)) {
                if (outputRef.current) {
                    handleUpdateMainMemo(outputRef.current.innerHTML);

                    // emit to the server that the user is no longer editing the main memo
                    try {
                        socket.emit("put.activeUsers", {
                            admin_uuid: userId,
                            is_main_memo: true,
                            main_memo_id: memoId,
                            active: false,
                        });
                    } catch (err) {
                        console.log(err);
                    }
                }
                setIsEditing(false);
                setMemoOptionsToolbarOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickoutside);

        return () => {
            document.removeEventListener("mousedown", handleClickoutside);
        };
    }, [memo]);

    const handleStartEditing = () => {
        if (!isEditing) {
            // save the previous memo content
            setPrevMemo(memo);
                        
            // emit to the server that the user is editing the main memo
            try {
                socket.emit("put.activeUsers", {
                    admin_uuid: userId,
                    is_main_memo: true,
                    main_memo_id: memoId,
                    active: true,
                });
            } catch (err) {
                console.log(err);
            }
        }

        setIsEditing(true);
        setMemoOptionsToolbarOpen(true);
    };

    const handleUpdateMainMemo = async (content) => {
        try {
            // save the text to localStorage for undo
            const prevWeeklyMainMemos = localStorage.getItem("prevWeeklyMainMemos") ? JSON.parse(localStorage.getItem("prevWeeklyMainMemos")) : [];
            prevWeeklyMainMemos.push({
                id: memoId ? memoId : null,
                memo: prevMemo,
            });
            localStorage.setItem("prevWeeklyMainMemos", JSON.stringify(prevWeeklyMainMemos));
            
            socket.emit("update.WeeklyMainMemos", {
                id: memoId,
                memo: content,
            });
        } catch (err) {
            console.log(err);
        }
    };

    const handleDeleteMainMemo = async (id) => {
        try {
            socket.emit("delete.WeeklyMainMemos", {
                id: id,
            });
        } catch (err) {
            console.log(err);
        }
    };

    const containerStyle = {
        position: "relative",
        marginBottom: 2,
        resize: "horizontal",
        overflow: "hidden",
        minWidth: "400px",
        maxWidth: "1000px",
        height: "490px",
        border: "1px solid #ccc",
        padding: "10px",
        margin: 0,
    };

    const memoBoxStyle = {
        marginBottom: 2,
        overflow: "auto",
        minWidth: "300px",
        maxWidth: "1000px",
        height: "90%",
        border: "1px solid #ccc",
        padding: "10px",
        color: memo ? "#333" : "#999",
        lineHeight: "1.2",
        fontSize: "11px", // default font size
    };

    return (
        <div ref={containerRef} className="container" style={containerStyle} onClick={handleStartEditing}>
            {activeUsersNum > 0 && (
                <Box
                    sx={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                    }}
                >
                    <RateReviewIcon />
                </Box>
            )}
            {!isEditing ? (
                <Box 
                    className="memo-view"
                    sx={{ 
                        width: "100%", 
                        height: "100%",
                        border: "1px solid transparent",
                        lineHeight: "1.2",
                        fontSize: "11px",
                        overflow: "auto",
                        '&:focus': {
                            border: `1px solid ${colors.grey[700]}`,
                        },
                    }}
                >
                    {memo ? (
                        <div 
                            dangerouslySetInnerHTML={{ __html: memo }}>
                        </div>
                    ) : (
                        <div style={{opacity: 0.4}}>
                            <h2>No memo written yet.. </h2>
                        </div>
                    )}
                </Box>
            ) : (
                <>
                    {memoOptionsToolbarOpen && (
                        <MainMemoOptionsToolbar
                            toolbarRef={toolbarRef} 
                            colors={colors} 
                            isMainMemo={true}
                        />
                    )}

                    <div
                        id="output"
                        contentEditable="true"
                        ref={outputRef}
                        className="editor"
                        data-placeholder="Enter text here..."
                        style={memoBoxStyle}
                    >
                        {memo || ""}
                    </div>

                    <Box
                        sx={{ 
                            display: "flex", 
                            justifyContent: "center", 
                            alignItems: "center",
                            height: "10%",
                            gap: 2 
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => setConfirmModalOpen(true)}
                        >
                            Remove
                        </Button>
                    </Box>
                </>
            )}
            {confirmModalOpen && (
                <Dialog
                    open={confirmModalOpen}
                    onClose={() => setConfirmModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete Memo"}
                    </DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete this memo?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setConfirmModalOpen(false)}>Cancel</Button>
                        <Button 
                            onClick={() => {
                                handleDeleteMainMemo(memoId);
                                setConfirmModalOpen(false);
                            }} 
                            color="error" 
                            autoFocus
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default TextEditor;