import React from 'react'
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import MoreVertIcon from "../../../components/MoreVertIcon"
import Chip from '@mui/material/Chip';
import DeleteDialog from '../../../components/DeleteDialog';
import CircleIcon from '@mui/icons-material/Circle';
import DuoOutlinedIcon from '@mui/icons-material/DuoOutlined';
import Axios from 'axios';
// import dayjs from 'dayjs';
import UpdateModal from './UpdateModal';
import AreYouSureModal from '../../../components/AreYouSureModal';
// import CompleteConfirmModal from './CompleteConfirmModal';
import getStudentName from '../../../utils/getStudentName';

const ZoomRecordingBox = ({ 
    courseId,
    userRole,
    userId,
    students,
    lectureModules,
    zoomRecording,
    setBackdropOpen, 
    setSuccessAlertOpen, 
    setErrorAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertMessage,
    setDataUpdated,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);

    // for popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMoreVertIconClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopOverClose = () => {
        setAnchorEl(null);
    };
    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    // for modal
    // const [modalOpen, setModalOpen] = React.useState(false);

    // const handleModalOpen = () => {
    //     setModalOpen(true);
    // };

    // const handleModalClose = () => {
    //     setModalOpen(false);
    // };

    // Function to format the event start date (ex. (Mon) 07/20/2024)
    const formatDate = (date) => {
        const options = { weekday: 'short', month: '2-digit', day: '2-digit', year: 'numeric' };
        const formattedDate = new Date(date).toLocaleDateString('en-US', options);
        const [dayOfWeek, rest] = formattedDate.split(', ');
        const [month, day, year] = rest.split('/');
        return `(${dayOfWeek}) ${month}/${day}/${year}`;
    };

    const formatDateForTitle = (date) => {
        const options = { weekday: 'long', month: '2-digit', day: '2-digit', year: 'numeric' };
        const formattedDate = new Date(date).toLocaleDateString('en-US', options);
        const [dayOfWeek, rest] = formattedDate.split(', ');
        const [month, day, year] = rest.split('/');
        return `(${dayOfWeek}, ${month}/${day}/${year})`;
    };

    const formatExpirationTime = (date) => {
        const formattedTime = new Date(date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        return formattedTime;
    }

    const handleDeleteZoomRecording = async () => {
        setAnchorEl(null);
        setBackdropOpen(true);

        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/zoom-recordings/${zoomRecording.id}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((res) => {
            setSuccessAlertMessage(res.data.message);
            setSuccessAlertOpen(true);
        }).catch((err) => {
            setErrorAlertMessage(err.response.data.message || err.response.data.error);
            setErrorAlertOpen(true);
        }).finally(() => {
            setDataUpdated(true);
            setBackdropOpen(false);
        });
    };

    const handleComplete = async () => {
        setBackdropOpen(true);

        if (userRole !== "student") {
            setErrorAlertMessage("Only students themselves can mark the recording as watched.");
            setErrorAlertOpen(true);
            setBackdropOpen(false);
            return;
        }

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/zoom-recordings/${zoomRecording.id}/complete`, {
            student_uuid: userId,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((res) => {
            setSuccessAlertMessage(res.data.message);
            setSuccessAlertOpen(true);
        }).catch((err) => {
            setErrorAlertMessage(err.response.data.message || err.response.data.error);
            setErrorAlertOpen(true);
        }).finally(() => {
            setDataUpdated(true);
            setBackdropOpen(false);
        });
    };

    // for submission indicator logic
    const studentsSubmissionRender = () => {
        let studentsWhoWatched = [];
        let studentsWhoDidNotWatch = [];

        zoomRecording.student_z_recordings.map((studentZRecording) => {
            if (studentZRecording.is_watched) {
                studentsWhoWatched.push(getStudentName(studentZRecording.student));
            } else {
                studentsWhoDidNotWatch.push(getStudentName(studentZRecording.student));
            }

            return null;
        });
        
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                    width: "100%",
                    py: "0.5rem",
                    px: "1rem",
                    gap: "0.5rem",
                }}
            >
                {studentsWhoWatched !== null && 
                    studentsWhoWatched.map((student, index) => {
                        return <Chip key={index} label={student}  color='secondary' />
                    })
                }
                {studentsWhoDidNotWatch !== null &&
                    studentsWhoDidNotWatch.map((student, index) => {
                        return <Chip key={index} label={student} />
                    })
                }
            </Box>
        );
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: colors.primary[600],
                borderRadius: "5px",
                border: "0.1px solid #a3a3a3",
                mb: "1.8rem",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    borderBottom: "0.1px solid #a3a3a3",
                    py: "0.5rem",
                    px: "1rem",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ width: 30, height: 30 }} >
                        <DuoOutlinedIcon />
                    </Avatar>
                    <Typography variant="h5" sx={{my: "auto", ml: "1rem"}}>
                        {zoomRecording.event.title}'s Zoom Recording {formatDateForTitle(zoomRecording.event.start)}
                    </Typography>
                </Box>
                <Box>
                    <MoreVertIcon handleClick={handleMoreVertIconClick} />
                    <Popover
                        id={popoverId}
                        open={popoverOpen}
                        anchorEl={anchorEl}
                        onClose={handlePopOverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        {userRole === "admin" &&
                            <>
                                <UpdateModal 
                                    students={students}
                                    lectureModules={lectureModules}
                                    zoomRecording={zoomRecording}
                                    setBackdropOpen={setBackdropOpen}
                                    setSuccessAlertOpen={setSuccessAlertOpen}
                                    setErrorAlertOpen={setErrorAlertOpen}
                                    setSuccessAlertMessage={setSuccessAlertMessage}
                                    setErrorAlertMessage={setErrorAlertMessage}
                                    setDataUpdated={setDataUpdated}
                                />
                                <DeleteDialog
                                    handleDelete={handleDeleteZoomRecording}
                                    setAnchorEl={setAnchorEl}
                                    dialogTitle={"Are you sure you want to delete this recording link?"} 
                                />
                            </>
                        }
                    </Popover>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    borderBottom: "0.1px solid #a3a3a3",
                    py: "1rem",
                    px: "0.3rem",
                }}
            >
                <Typography variant="h7" color={colors.grey[700]} sx={{my: "auto", ml: "1rem"}}>
                    Expiration Date: {formatDate(zoomRecording.expiration_date)} {formatExpirationTime(zoomRecording.expiration_date)}
                </Typography>
                <Typography target='_blank' component='a' href={zoomRecording.link} variant='h5' sx={{mt: "1rem", ml: "1rem"}}>
                    Click this hyperlink to watch the recording
                </Typography>
                <Typography variant='body2' sx={{mt: "1rem", ml: "1rem"}}>
                    If you finish watching this recording, please click the "Complete" button below to mark it as watched.
                </Typography>
                {/* memo */}
                {zoomRecording.description ?
                    <Typography variant='body2' sx={{mt: "1rem", ml: "1rem"}}>
                        {zoomRecording.description}
                    </Typography>
                : null}
                <Box
                    sx={{
                        mt: "1rem",
                        mx: "1rem"
                    }}
                >
                    <Button 
                        type="button"
                        variant="contained"
                        onClick={() => setAreYouSureModalOpen(true)}
                        // onClick={handleModalOpen}
                        style={{
                            backgroundColor: colors.primary[300],
                            color: "white",
                            width: "9rem",
                            fontSize: "10px",
                            float: "right",
                        }}
                    >
                        Complete
                    </Button>
                    <AreYouSureModal
                        colors={colors}
                        open={areYouSureModalOpen}
                        setOpen={setAreYouSureModalOpen}
                        title="Did you finish watching the recording?"
                        message="This will mark the recording as watched, and it will no longer be available for you to watch again. If you don't complete it, whether you've watched it or not, we will consider it unwatched."
                        onConfirm={handleComplete}
                    />
                    {/* <CompleteConfirmModal 
                        open={modalOpen}
                        onClose={handleModalClose}
                        onSubmit={handleComplete}                            
                    /> */}
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%",
                    py: "0.5rem",
                    px: "1rem",
                }}
            >
                <CircleIcon fontSize='10px' color='secondary' />
                <Typography variant='h9' sx={{ml: "0.3rem"}}>
                    Watched
                </Typography>
                <CircleIcon fontSize='10px' sx={{ml: "1rem", color: colors.grey[950]}} />
                <Typography variant='h9' sx={{ml: "0.3rem"}}>
                    Not Watched
                </Typography>
            </Box>
            {studentsSubmissionRender()}
        </Box>
    )
}

export default ZoomRecordingBox